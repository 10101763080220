<template>
  <div>
    结算
  </div>
</template>

<script>
  export default {
    name: "Settlement"
  }
</script>

<style scoped>

</style>